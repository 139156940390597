import { Injectable } from '@angular/core';
import { BaseService } from '../../core/base.service';
import { Vendor } from './vendor';
import Query from '../../core/query/query';
import { EntityLockService } from '../../shared/entity-lock/entity-lock.service';

@Injectable()
export class VendorService extends BaseService<Vendor> {
	constructor(private entityLock: EntityLockService) {
		super('vendor', entityLock);
	}

	getEntities(id: number) {
		return this.httpService().get([this.name, id, 'entities'], { limit: 100000 });
	}

	addEntity(id: number, entity: string): any {
		return this.httpService().post([this.name, id, 'entities'], entity);
	}

	assignEntity(id: number, entityId: number): any {
		return this.httpService().post([this.name, id, 'entities', entityId]);
	}

	removeEntity(id: number, entityId: number) {
		return this.httpService().delete([this.name, id, 'entities', entityId]);
	}

	findAllEntities(vendorId: number): any {
		return this.httpService().get([this.name, vendorId, 'entities']);
	}

	findAllinEntityList(query?: Query): any {
		return this.httpService().get([this.name, 'allEntities'], query);
	}

	findAllEntitiesByQuery(vendorId: number, query?: any): any {
		return this.httpService().get([this.name, vendorId, 'entities'], query);
	}

	findAllWithActiveBANsAndInvoices(): any {
		return this.httpService().get([this.name, 'findAllWithActiveBANsAndInvoices']);
	}

	findVendorsWithBans(query?: Query): any {
		let concreteQuery = query || new Query();
		let transformedQuery = concreteQuery.transform();
		return this.httpService().post([this.name, 'findVendorsWithBans'], this.toFilter(transformedQuery));
	}

	findVendorsWithActiveBans(query?: Query): any {
		let concreteQuery = query || new Query();
		let transformedQuery = concreteQuery.transform();
		return this.httpService().post([this.name, 'findVendorsWithActiveBans'], this.toFilter(transformedQuery));
	}

	findAllWithActiveBANsAndSubAccounts(): any {
		return this.httpService().get([this.name, 'findAllWithActiveBANsAndSubAccounts']);
	}

	codingUpdate(id, vendor): any {
		return this.httpService().put([this.name, 'coding', id], vendor);
	}

	findOnlyVendors(query?: Query): any {
		return this.httpService().get([this.name, 'findOnlyVendors'], query);
	}

	findVendorsForPicker(query?: Query): any {
		return this.httpService().get([this.name, 'findVendorsForPicker'], query);
	}

	findOnlyVendorsAccPicker(query?: Query): any {
		return this.httpService().get([this.name, 'findOnlyVendorsAccPicker'], query);
	}

	findOnlySimpleVendors(query?: Query): any {
		return this.httpService().get([this.name, 'findOnlySimpleVendors'], query);
	}
}
