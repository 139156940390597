import { Injectable } from '@angular/core';

const chargeTypes: string[] = ['adjad', 'adjbf', 'adjnc', 'mrc', 'occ', 'disc', 'taxsur', 'tax', 'sur', 'usgsum', 'usg'];

const CHARGE_TYPES_BY_CATEGORIES = {
	default: ['ajdad', 'adjbf', 'adjnc', 'mrc', 'occ', 'disc'],
	taxSur: ['tax', 'sur', 'taxsur'],
	usage: ['usgsum', 'usg']
};

const chargeCategories: string[] = ['Late Payment Charge'];

const fields: Array<any> = [
	{
		dataField: 'chg_class',
		caption: 'Charge Type',
		filterOperations: ['=', '<>', 'in', 'notIn'],
		lookup: {
			dataSource: chargeTypes
		}
	},
	{
		dataField: 'charge_category',
		caption: 'Charge Category',
		filterOperations: ['=', '<>', 'in', 'notIn'],
		lookup: {
			dataSource: chargeCategories
		}
	},
	{
		dataField: 'sp_serv_id',
		caption: 'SPID'
	},
	{
		dataField: 'acct_level_2',
		caption: 'SubAccount'
	},
	{
		dataField: 'chg_code_1',
		caption: 'Charge Code 1'
	},
	{
		dataField: 'chg_desc_1',
		caption: 'Charge Description 1'
	},
	{
		dataField: 'chg_code_2',
		caption: 'Charge Code 2'
	},
	{
		dataField: 'chg_desc_2',
		caption: 'Charge Description 2'
	},
	{
		dataField: 'chg_code_3',
		caption: 'Charge Code 3'
	},
	{
		dataField: 'chg_desc_3',
		caption: 'Charge Description 3'
	}
];

@Injectable()
export class NewRuleBUilderService {
	getFields(chargeTypeCategory?: string): Array<any> {
		if (chargeTypeCategory) {
			return fields.map((field) => {
				return field.dataField === 'chg_class'
					? {
							...field,
							lookup: {
								dataSource: CHARGE_TYPES_BY_CATEGORIES[chargeTypeCategory]
							}
						}
					: field;
			});
		} else {
			return fields;
		}
	}

	getFilter(): Array<any> {
		return [['chg_class', '=', 'mrc']];
	}
}
