<div style="width: 100%; display: flex !important; justify-content: flex-end" #item>
	<button
		mat-button
		#confirmButton
		(click)="delete()"
		[disabled]="(confirm && requestInput && userInput !== entityName) || disabled"
		(focusout)="confirm && !requestInput && closeConfirm()"
		[type]="type"
	>
		{{ confirm ? 'Confirm' : deleteText }}
	</button>

	<div class="confirm-input ca-form" [ngStyle]="confirmStageWidth()" [ngClass]="{ visible: confirm && requestInput }" *ngIf="requestInput">
		<mat-form-field hiDensityInput class="cofirm-delete-input" style="max-width: 70%; height: 40px">
			<mat-label>{{ placeHolder }}</mat-label>
			<input matInput #confirmInput id="confirm-input" [(ngModel)]="userInput" (focusout)="closeConfirm()" type="text" />
		</mat-form-field>
	</div>

	<div class="confirm-message" [ngStyle]="confirmStageWidth()" [ngClass]="{ visible: confirm && !requestInput }" *ngIf="!requestInput"></div>
</div>
