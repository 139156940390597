<div class="mat-dialog-top-spacer"></div>

<div class="ca-dialog-title" style="position: relative">
	<span fxFlex="65%">
		<h1 mat-dialog-title>{{ formTitle }}</h1>
	</span>
</div>
<div mat-dialog-content class="ca-dialog-content app-vendor-manage-dialog" style="min-height: 150px; margin-top: 0 !important" *ngIf="form">
	<form [formGroup]="form" class="ca-form" fxLayout="row" style="margin-top: 0 !important">
		<div fxItem class="caGap caGapRight">
			<ca-avatar
				avatarCropperClass="vendor-cropper"
				fxLayout="row"
				[uploadEnabled]="true"
				fxLayoutAlign="center center"
				class="details-item vendor-avatar"
				(onAvatarUpload)="handleLogoUpload($event)"
				[text]="getVendorInitials()"
				[size]="'m'"
				[source]="vendorCopy?.logo"
				[disabled]="viewOnly"
			></ca-avatar>
		</div>
		<div fxItem style="width: 100%" [ngClass]="!fields || (fields && fields.length) ? 'caGapRight' : ''">
			<div fxLayout="column">
				<mat-form-field class="caGap" style="width: 100%" caInput>
					<mat-label>Name</mat-label>
					<input matInput formControlName="name" type="text" [disabled]="viewOnly" />
					<mat-hint *ngIf="form.hasError('required', ['name']) && form.controls.name.touched" class="error-class">{{ VALIDATION.REQUIRED_ERROR }}</mat-hint>
				</mat-form-field>

				<div class="caGap vendor-modal-quoting">
					<mat-checkbox class="vendor__is-quoting-checkbox" formControlName="is_quoting" [disabled]="viewOnly">Quoting</mat-checkbox>
				</div>

				<div class="vendor-model-entities">
					<div fxLayout="row" style="align-items: center" *ngIf="isUpdate">
						<h3 class="flex-container" style="margin: 0px">Entities</h3>
						<span class="example-fill-remaining-space"></span>

						<button class="add-entity-button" [disabled]="viewOnly" mat-icon-button tabindex="-1" matTooltip="Add Entity" (click)="newVendorEntity(vendor)">
							<ca-icon [key]="'VENDOR_ADD_ENTITY'"></ca-icon>
						</button>
					</div>

					<div class="ca-empty-state-container entity-container" *ngIf="isUpdate && vendor?.entities?.length == 0 && entities?.length == 0">
						<p class="small-div-message" style="width: 100%">No Entries selected</p>
					</div>

					<div *ngIf="isUpdate && (vendor?.entities?.length > 0 || entities?.length > 0)" class="entity-container ca-default-border">
						<ul class="ca-list-group ca-entry-list">
							<li class="ca-list-group-item ca-default-border-color" *ngFor="let entity of vendor?.entities">
								<div fxLayout="row" class="vertical-center-container">
									<div fxFlex style="display: flex">
										<i class="material-icons" (click)="moveVendorEntity(vendor, entity)" *ngIf="!viewOnly && entity.id">compare_arrows</i>
										<span [ngClass]="{ 'no-icon': !entity.id }" class="entry-label">{{ entity.name }}</span>
									</div>
									<div fxFlex="10%" class="delete-button-container right" fxFlexAlign="center">
										<button mat-icon-button (click)="deleteVendorEntity(vendor, entity)" [disabled]="viewOnly">
											<ca-icon [key]="'VENDOR_DELETE_ENTITY'"></ca-icon>
										</button>
									</div>
								</div>
							</li>

							<li class="ca-list-group-item ca-default-border-color" *ngFor="let entity of entities">
								<div fxLayout="row" class="vertical-center-container">
									<div fxFlex style="display: flex">
										<i class="material-icons" (click)="moveVendorEntity(vendor, entity)">compare_arrows</i>
										<span class="entry-label">{{ entity.name }}</span>
									</div>
									<div fxFlex="10%" class="delete-button-container right" fxFlexAlign="center">
										<button (click)="deleteVendorEntity(vendor, entity)" mat-icon-button [disabled]="viewOnly">
											<ca-icon [key]="'VENDOR_DELETE_ENTITY'"></ca-icon>
										</button>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="fields && fields.length" fxFlex="300px" fxLayout="column" style="position: relative">
			<ca-custom-fields
				[form]="this.form"
				[customFieldService]="this.vendorService"
				[customField]="this.vendor"
				[fields]="fields"
				[categories]="categories"
				[showCategory]="showCategory"
				[clearEnabled]="true"
				[onlyRequiredCustomFields]="false"
			></ca-custom-fields>
		</div>
	</form>
</div>

<mat-dialog-actions class="ca-dialog-action" *ngIf="!viewOnly">
	<div class="example-fill-remaining-space">
		<ca-confirm-delete-button
			#genericButton
			*ngIf="vendor && vendor.id && deletePermission"
			[requestInput]="true"
			[deleteText]="actionText"
			[snackBarDeleteInputMessage]="'Please type in ' + randomString + ' to ' + actionText"
			[placeHolder]="deletePlaceholder"
			[entityName]="randomString"
			(deleteRequested)="deleteVendor($event)"
		>
		</ca-confirm-delete-button>
	</div>
	<button #cancelButton mat-button type="submit" (click)="cancel()">Cancel</button>
	<button mat-button #saveButton type="submit" (click)="onSubmit(form)" [disabled]="form?.invalid">Save</button>
</mat-dialog-actions>

<mat-dialog-actions class="ca-dialog-action" *ngIf="viewOnly">
	<div class="example-fill-remaining-space"></div>

	<button mat-button #cancelButton type="submit" (click)="closeDialog()">Cancel</button>

	<button *permission="{ action: 'Modify', key: 'vendors' }" mat-button type="submit" (click)="editForm()">Edit</button>
</mat-dialog-actions>
