import { Injectable } from '@angular/core';
import { GridService } from '../../shared/grid/grid.service';
import { FormatService } from '../../shared/format/format.service';
import { UserSettingsService } from '../../user/core/user-settings.service';

@Injectable()
export class AlertRateAuditResultGridService extends GridService {
	defaultColumns = [
		{
			caption: '',
			headerCellTemplate: 'selectedChargesHeaderTemplate',
			cellTemplate: 'chargeDisputeNoteTemplate',
			width: 40,
			fixed: true,
			allowSorting: false
		},
		{
			caption: 'Charge ID',
			dataField: 'charge_id',
			width: 140
		},
		{
			caption: 'Begin Date',
			dataField: 'cost_invoice_charge.beg_chg_date',
			dataType: 'date',
			width: 90,
			format: 'shortDate',
			alignment: 'left'
		},
		{
			caption: 'End Date',
			dataField: 'cost_invoice_charge.end_chg_date',
			dataType: 'date',
			width: 90,
			format: 'shortDate',
			alignment: 'left'
		},
		{
			caption: 'SPID',
			dataField: 'cost_invoice_charge.sp_serv_id',
			width: 160
		},
		{
			caption: 'Charge Type',
			dataField: 'chg_class',
			visible: true,
			width: 120
		},
		{
			dataField: 'cost_invoice_charge.chg_desc_1',
			caption: 'Charge Description 1',
			width: 220
		},
		{
			caption: 'Billed Qty',
			dataField: 'cost_invoice_charge.chg_qty1_billed',
			alignment: 'right',
			dataType: 'number',
			width: 120
		},
		{
			caption: 'Charge Rate',
			dataField: 'cost_invoice_charge.chg_rate',
			alignment: 'right',
			dataType: 'number',
			calculateCellValue: (rowData) => {
				if (rowData.cost_invoice_charge.chg_class === 'usgnum') {
					return new Intl.NumberFormat('en-US', {
						style: 'currency',
						currency: 'USD',
						minimumFractionDigits: 4,
						maximumFractionDigits: 4,
						currencyDisplay: 'narrowSymbol'
					}).format(rowData.cost_invoice_charge.chg_rate);
				}

				return new Intl.NumberFormat('en-US', {
					style: 'currency',
					currency: 'USD',
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
					currencyDisplay: 'narrowSymbol'
				}).format(rowData.cost_invoice_charge.chg_rate);
			},
			width: 120
		},
		{
			caption: 'Charge Amt',
			dataField: 'cost_invoice_charge.chg_amt',
			alignment: 'right',
			dataType: 'number',
			format: {
				precision: 2,
				type: 'currency'
			},
			width: 120
		},
		{
			caption: 'Calc Rate',
			dataField: 'rate_value',
			dataType: 'number',
			alignment: 'right',
			width: 120,
			calculateCellValue: (rowData) => {
				if (rowData.cost_invoice_charge.chg_class === 'usgnum') {
					return new Intl.NumberFormat('en-US', {
						style: 'currency',
						currency: 'USD',
						minimumFractionDigits: 4,
						maximumFractionDigits: 4,
						currencyDisplay: 'narrowSymbol',
						notation: 'standard'
					}).format(rowData.rate_value);
				}

				return new Intl.NumberFormat('en-US', {
					style: 'currency',
					currency: 'USD',
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
					currencyDisplay: 'narrowSymbol',
					notation: 'standard'
				}).format(rowData.rate_value);
			}
		},
		{
			caption: 'Calc Charge',
			dataField: 'calc_chg_amt',
			dataType: 'number',
			alignment: 'right',
			format: {
				precision: 2,
				type: 'currency'
			},
			width: 120
		},
		{
			caption: 'Variance',
			dataField: 'chg_var',
			dataType: 'number',
			alignment: 'right',
			format: {
				precision: 2,
				type: 'currency'
			},
			width: 120
		}
	];

	constructor(
		public userSettings: UserSettingsService,
		public formatService: FormatService
	) {
		super('app.alert.rate-audit-result-grid', userSettings);
	}
}
