import { Component, OnInit, EventEmitter, Output, Input, ViewChild, AfterViewInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'ca-confirm-delete-button',
	templateUrl: './confirm-delete-button.component.html',
	styleUrls: ['./confirm-delete-button.component.css']
})
export class ConfirmDeleteButtonComponent implements OnInit, AfterViewInit {
	public confirm: boolean;
	@ViewChild('item') item;
	@Input() confirmText = '';
	@Input() customMessage = '';
	@Input() requestInput: boolean;
	@Input() type: string;
	@Input() entityName: string;
	@Input() placeHolder = 'Enter name to confirm delete';
	@Input() hostContainerWidth = 0;
	@Input() disabled: boolean;
	public userInput: string;
	@Input() snackBarDeleteMessage = 'Click Confirm to delete';
	@Input() snackBarDeleteInputMessage = 'Enter value and click Confirm to delete';
	@Input() deleteText = 'Delete';

	@Output() deleteRequested = new EventEmitter();

	@ViewChild('confirmInput') confirmInput;
	@ViewChild('confirmButton') confirmButton;

	private containerWidth = 0;

	constructor(public snackBar: MatSnackBar) {}

	ngAfterViewInit(): void {
		this.containerWidth = this.hostContainerWidth || this.item.nativeElement.clientWidth;
	}

	confirmStageWidth() {
		if (this.confirm) {
			return { 'flex-grow': '1' };
		}
		return { width: '0' };
	}

	delete() {
		if (this.confirm) {
			this.deleteRequested.emit(true);
		} else {
			if (this.customMessage) {
				let message = this.customMessage;
				this.snackBar.open(message);
				this.confirm = true;
				this.confirmButton.focus();
			} else {
				let message = this.requestInput ? this.snackBarDeleteInputMessage : this.snackBarDeleteMessage;
				this.snackBar.open(message);
				this.confirm = true;

				if (this.requestInput) {
					this.confirmInput.nativeElement.focus();
				} else {
					this.confirmButton.focus();
				}
			}
		}
	}

	closeConfirm() {
		setTimeout(() => {
			this.snackBar.dismiss();
			this.confirm = false;
			this.userInput = '';
		}, 250);
	}

	ngOnInit() {}
}
