import { Injectable } from '@angular/core';
import { GridService } from '../../shared/grid/grid.service';
import { FormatService } from '../../shared/format/format.service';
import { UserSettingsService } from '../../user/core/user-settings.service';
import { sumBy } from 'lodash';

@Injectable()
export class AlertInventoryMrcChargesGridService extends GridService {
	defaultColumns = [
		{
			caption: '',
			headerCellTemplate: 'selectedChargesHeaderTemplate',
			cellTemplate: 'chargeDisputeNoteTemplate',
			width: 40,
			fixed: true
		},
		{
			caption: 'Charge ID',
			dataField: 'id',
			width: 140
		},
		{
			caption: 'Subaccount',
			dataField: 'acct_level_2',
			width: 200
		},
		{
			caption: 'SPID',
			dataField: 'sp_serv_id',
			width: 200
		},
		{
			caption: 'Charge Description 1',
			dataField: 'chg_desc_1',
			width: 200
		},
		{
			caption: 'Charge Description 2',
			dataField: 'chg_desc_2',
			width: 200
		},
		{
			caption: 'Begin Date',
			dataField: 'beg_chg_date',
			dataType: 'date',
			width: 120,
			format: 'shortDate',
			alignment: 'left'
		},
		{
			caption: 'End Date',
			dataField: 'end_chg_date',
			dataType: 'date',
			width: 120,
			format: 'shortDate',
			alignment: 'left'
		},
		{
			caption: 'Charge Amount',
			dataField: 'chg_amt',
			cellTemplate: 'invoiceCurrencyTemplate',
			width: 170
		},
		{
			caption: 'Rate Eff Date',
			dataField: 'effective_date',
			dataType: 'date',
			width: 120,
			format: 'shortDate',
			alignment: 'left'
		},
		{
			caption: 'Rate Term Date',
			dataField: 'termination_date',
			dataType: 'date',
			width: 120,
			format: 'shortDate',
			alignment: 'left'
		},
		{
			caption: 'Rate Status',
			dataField: 'is_active',
			dataType: 'boolean',
			width: 120,
			alignment: 'left',
			cellTemplate: 'statusTemplate'
		}
	];

	constructor(
		public userSettings: UserSettingsService,
		public formatService: FormatService
	) {
		super('app.alert.inventory-mrc-charge-grid', userSettings);
	}

	getFullColumns(totalCharges: number) {
		return [
			{
				caption: '',
				headerCellTemplate: 'selectedChargesHeaderTemplate',
				cellTemplate: 'chargeDisputeNoteTemplate',
				width: 40,
				fixed: true
			},
			{
				caption: 'Charge ID',
				dataField: 'id',
				width: 140
			},
			{
				caption: 'Subaccount',
				dataField: 'acct_level_2',
				width: 200
			},
			{
				caption: 'SPID',
				dataField: 'sp_serv_id',
				width: 200
			},
			{
				caption: 'Charge Description 1',
				dataField: 'chg_desc_1',
				width: 200
			},
			{
				caption: 'Charge Description 2',
				dataField: 'chg_desc_2',
				width: 200
			},
			{
				caption: 'Begin Date',
				dataField: 'beg_chg_date',
				dataType: 'date',
				width: 120,
				format: 'shortDate',
				alignment: 'left'
			},
			{
				caption: 'End Date',
				dataField: 'end_chg_date',
				dataType: 'date',
				width: 120,
				format: 'shortDate',
				alignment: 'left'
			},
			{
				caption: 'Charge Amount',
				dataField: 'chg_amt',
				cellTemplate: 'invoiceCurrencyTemplate',
				width: 170
			},
			{
				caption: 'Calculated Inventory MRC',
				name: 'est_mrc',
				alignment: 'center',
				cellTemplate: 'calculatedInventoryTemplate',
				calculateCellValue: (rowData) => {
					const percentage = Number(rowData.chg_amt) / totalCharges;

					return Number(percentage * rowData.est_mrc);
				},
				width: 200
			},
			{
				caption: 'Variance',
				name: 'variance',
				alignment: 'center',
				cellTemplate: 'varianceTemplate',
				calculateCellValue: (rowData) => {
					const percentage = Number(rowData.chg_amt) / totalCharges;
					const inventoryMrc = Number(percentage * rowData.est_mrc);

					return Number(rowData.chg_amt) - inventoryMrc;
				},
				width: 200
			},
			{
				caption: 'Rate Eff Date',
				dataField: 'effective_date',
				dataType: 'date',
				width: 120,
				format: 'shortDate',
				alignment: 'left'
			},
			{
				caption: 'Rate Term Date',
				dataField: 'termination_date',
				dataType: 'date',
				width: 120,
				format: 'shortDate',
				alignment: 'left'
			},
			{
				caption: 'Rate Status',
				dataField: 'is_active',
				dataType: 'boolean',
				width: 120,
				alignment: 'left',
				cellTemplate: 'statusTemplate'
			}
		];
	}
}
