import { Injectable } from '@angular/core';
import { BaseService } from '../../core/base.service';
import { DictionaryService } from '../../dictionary/core/dictionary.service';
import { GLString } from './gl-string';
import Query from '../../core/query/query';
import { LOOKUP_MODELS_ENUM } from '../../dictionary/core/lookup-models.enum';
import { EntityLockService } from '../../shared/entity-lock/entity-lock.service';
import { map } from 'rxjs';

@Injectable()
export class GLStringService extends BaseService<GLString> {
	constructor(
		public dictionaryService: DictionaryService,
		private entityLock: EntityLockService
	) {
		super('gl_string', entityLock);
	}

	findAllWithCharges(query?: Query): any {
		let concreteQuery = query || new Query();
		let transformedQuery = concreteQuery.transform();
		transformedQuery = this.removeSpecialParams(transformedQuery);
		return this.httpService().get([this.name, 'string_invoice'], this.toFilter(transformedQuery));
	}

	removeSpecialParams(query) {
		const ignored = ['$gpx'];
		if (!query.where) query.where = {};
		let where = {};
		Object.keys(query.where).forEach((key) => {
			const param = query.where[key];
			if (typeof param !== 'object' || param === null) {
				where[key] = param;
			} else {
				const reduced = {};
				Object.keys(param).forEach((k) => {
					if (ignored.indexOf(k) === -1) {
						reduced[k] = param[k];
					}
				});
				where[key] = reduced;
			}
		});

		return Object.assign({}, query, { where });
	}

	public findGLSegments() {
		return new Promise((resolve, reject) => {
			this.httpService()
				.get([this.name, 'string_count'])
				.subscribe((data) => {
					let lookupModel: string = LOOKUP_MODELS_ENUM.GL_CODE_SEGMENT.modelName;

					let query = new Query({
						limit: data.total,
						orderBy: ['id']
					});

					this.dictionaryService.getByLookup(lookupModel, query).subscribe((values) => {
						resolve(this.convert(values.items));
					});
				});
		});
	}

	update(id: number, item: GLString): any {
		return this.httpService()
			.put([this.name, 'status'], { object: item })
			.pipe(
				map((result) => {
					this.completeEdit();
					return result;
				})
			);
	}

	public convert(values) {
		values.forEach((element) => {
			try {
				element.system_key = `segment${element.id}`;
			} catch (err) {}
		});
		return values;
	}

	findIsStringInRule(id: number) {
		return this.httpService().get([this.name, id, 'inRule']);
	}

	findAllWithApportions({ ruleMeta, query, ruleType }) {
		query.ruleMeta = ruleMeta;
		query.orderBy = query.orderBy
			? query.orderBy.map((order) => {
					if (order[0] === 'allocation') {
						return [order[0], order[1], 'NULLS LAST'];
					}
					if (order[0] === 'apportion_pct') {
						return [order[0], order[1], 'NULLS LAST'];
					}

					return order;
				})
			: null;

		return this.httpService().get([this.name, `apportion/${ruleType}`], this.toFilter(query));
	}

	replace(selectedStrings: any, selectedRules: any) {
		return this.httpService().post([this.name, 'replace'], { selectedStrings: selectedStrings, selectedRules: selectedRules });
	}

	countReplace(selectedStrings: any) {
		return this.httpService().post([this.name, 'replace-count'], { selectedStrings: selectedStrings });
	}

	generateDefaultGridColumns(numberOfDigits: number, isBanStatic: boolean) {
		return [
			{
				caption: 'Apportion %',
				dataField: 'apportion_pct',
				alignment: 'right',
				dataType: 'number',
				validationRules: !isBanStatic
					? [
							{ type: 'required', message: 'Enter value' },
							{ type: 'range', min: 0, max: 100, message: 'From 0 to 100' },
							{ type: 'pattern', pattern: `^\\d+\\.?\\d{0,${numberOfDigits}}$|\\d{0,9}e-${numberOfDigits}`, message: `Do not use more than ${numberOfDigits} digits after decimal point.` }
						]
					: [
							{ type: 'required', message: 'Enter value' },
							{ type: 'pattern', pattern: `^-?\\d+\\.?\\d{0,${numberOfDigits}}$|\\d{0,9}e-${numberOfDigits}`, message: `Do not use more than ${numberOfDigits} digits after decimal point.` }
						],
				width: '120px',
				headerCellTemplate: 'apportionHeaderTemplate',
				format: {
					type: 'fixedPoint',
					precision: numberOfDigits
				}
			},
			{
				caption: 'Full GL String',
				dataField: 'full_string_formatted',
				allowEditing: false,
				width: '200px'
			},
			{
				caption: 'GL String Desc',
				dataField: 'full_string_text',
				allowEditing: false,
				width: '200px'
			}
		];
	}

	calculateTotalApportion(strings: Array<any>, glApportionFieldName: string, numberOfDigits: number, cleared: boolean = false) {
		let totalApportion = 0.0;
		if (!cleared && strings) {
			totalApportion = parseFloat(
				strings.reduce((sum: number, item: any) => {
					return Number(sum) + Number(item[glApportionFieldName]);
				}, 0)
			);
		}
		return totalApportion.toFixed(numberOfDigits);
	}
}
