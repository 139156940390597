import { of as observableOf, Observable, combineLatest, Subscription, ObjectUnsubscribedError } from 'rxjs';

import { startWith, takeUntil, mergeMap } from 'rxjs';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Form, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Config } from '../../../core/config/config';
import { ConfigService } from '../../../core/config/config.service';
import { PageManageDialogComponent } from '../../../core/page-manage-dialog.component';
import Query from '../../../core/query/query';
import { IMessagesResourceService, ResourcesService } from '../../../core/resources/resources.service';
import { Demarc } from '../../../demarc/core/demarc.model';
import { DemarcService } from '../../../demarc/core/demarc.service';
import { LOOKUP_MODELS_ENUM } from '../../../dictionary/core/lookup-models.enum';
import { LOOKUP_ENUM } from '../../../dictionary/core/lookup.enum';
import { Inventory } from '../../../inventory/core/inventory';
import { INVENTORY_TOPOLOGY_ENUM } from '../../../inventory/core/inventory-topology.enum';
import { InventoryService } from '../../../inventory/core/inventory.service';
import { NetworkHubService } from '../../../network-hub/core/network-hub.service';
import { Order } from '../../../order/core/order';
import { AlertService } from '../../../shared/alert/alert.service';
import { DialogService } from '../../../shared/dialog/dialog.service';
import { EntityEditContext } from '../../../shared/entity-lock/entity-edit-context';
import { TabGroup, TabService } from '../../../shared/tabs/tab.service';
import { SiteService } from '../../../site/core/site.service';
import { OrderService } from '../../core/order-service';
import { ORDER_SERVICE_STATUS } from '../../core/order-service-status.enum';
import { ORDER_SERVICE_DISCONNECT_STATUS } from '../../core/order-service-disconnect-status.enum';
import { ORDER_TYPE } from '../../../order/core/order-type.enum';

import { OrderServiceService } from '../../core/order-service.service';
import { Account } from '../../../account/core/account';
import { LookupModel } from '../../../dictionary/core/lookup.model';
import { OrderServiceFlowService } from 'app/order-service/core/order-service-flow.service';
import * as _ from 'lodash';
import { flatMap } from 'rxjs';

@Component({
	selector: 'app-order-service-manage-dialog',
	templateUrl: './order-service-manage-dialog.component.html',
	styleUrls: ['./order-service-manage-dialog.component.css']
})
export class OrderServiceManageDialogComponent extends PageManageDialogComponent implements OnInit, OnDestroy {
	order: Order;
	orderService: any;
	public fields: Array<any>;
	public topology;
	public singleDemarc = true;

	public tabGroup: TabGroup;
	public tabChanged: boolean;
	activeTabIndex = 0;

	public inventorySites: any = [];
	public internal_id: any = '';

	private prepared_fields: {};

	public orderTypeRequiredField: boolean = false;

	public custom_fields;
	public custom_fields1;
	public custom_fields2;
	public custom_fields3;
	public categories = [];
	public categories1 = [];
	public categories2 = [];
	public categories3 = [];
	public contactType = ['SITE_CONTACT', 'SECONDARY_SITE_CONTACT'];
	public contactFunctionsUI = [
		{ id: 1, value: 'Site Contact', order_number: 0, key: 'SITE_CONTACT' },
		{ id: 2, value: 'Secondary Site Contact', order_number: 0, key: 'SECONDARY_SITE_CONTACT' }
	];
	onlyRequiredCustomFields = true;
	onlyRequiredCustomFields1 = true;
	onlyRequiredCustomFields2 = true;
	onlyRequiredCustomFields3 = true;

	@ViewChild('hubPickerA') hubPickerA;

	INVALID_SP_CKT_ID_MSG = 'SP Circuit ID can contain only alphanumeric characters';

	readonly INVENTORY_BW_MODEL_LOOKUP_MODEL: string = LOOKUP_MODELS_ENUM.INVENTORY_BW_MODEL.modelName;
	readonly INVENTORY_TECHNOLOGY_LOOKUP_MODEL: string = LOOKUP_MODELS_ENUM.INVENTORY_TECHNOLOGY.modelName;
	readonly INVENTORY_PROTECTED_LOOKUP_MODEL: string = LOOKUP_MODELS_ENUM.INVENTORY_PROTECTED.modelName;
	readonly INVENTORY_PRIM_FAILOVER_LOOKUP_MODEL: string = LOOKUP_MODELS_ENUM.INVENTORY_PRIM_FAILOVER.modelName;
	readonly INVENTORY_CKT_TYPE_LOOKUP_MODEL: string = LOOKUP_MODELS_ENUM.INVENTORY_CKT_TYPE.modelName;
	readonly INVENTORY_TOPOLOGY_LOOKUP_MODEL: string = LOOKUP_MODELS_ENUM.INVENTORY_TOPOLOGY.modelName;
	readonly INVENTORY_CIRCUIT_USAGE_LOOKUP_MODEL: string = LOOKUP_MODELS_ENUM.INVENTORY_CIRCUIT_USAGE.modelName;
	readonly INVENTORY_HANDOFF_TYPE_LOOKUP_MODEL: string = LOOKUP_MODELS_ENUM.INVENTORY_HANDOFF_TYPE.modelName;
	readonly NETWORK_HUB_CAPACITY = LOOKUP_ENUM.NETWORK_HUB_CAPACITY;
	readonly CAPACITY_TYPES = LOOKUP_ENUM.NETWORK_HUB_CAPACITY_TYPES;
	readonly NETWORK_HUB_TYPE = LOOKUP_ENUM.NETWORK_HUB_TYPE;
	public INVENTORY_TOPOLOGY = INVENTORY_TOPOLOGY_ENUM;
	public ORDER_SERVICE_STATUS = ORDER_SERVICE_STATUS;
	readonly ORDER_SERVICE_DISCONNECT_STATUS = ORDER_SERVICE_DISCONNECT_STATUS;
	readonly ORDER_TYPE = ORDER_TYPE;

	messages: IMessagesResourceService;
	demarc_messages: IMessagesResourceService;
	readonly MESSAGES_MODULE: string = 'order';
	readonly MESSAGES_DEMARC_MODULE: string = 'demarc';

	public HUB_PICKER_LEVEL = {
		LEVEL_1: 1,
		LEVEL_2: 2
	};

	hub_labels = {
		level_1: 'Level 1',
		level_2: 'Level 2'
	};

	inventoryTechnologyQuery = new Query({
		where: {
			is_order: true
		},
		limit: 1000
	});

	technologyId;

	siteADemarcs: Array<Demarc>;
	siteADemarcFormMode: boolean;
	selectedSiteADemarc: Demarc;
	siteZDemarcs: Array<Demarc>;
	siteZDemarcFormMode: boolean;
	selectedSiteZDemarc: Demarc;

	siteADemarcTmp: Demarc = new Demarc({ unsaved: true });
	siteZDemarcTmp: Demarc = new Demarc({ unsaved: true });

	public level1: Object;
	public level2: Object;

	customAtt;
	minimumTermMonths: any;
	maximumTermMonths: any;
	termHasError: boolean;
	isUpdate: boolean;
	passedRfa: boolean;
	disabledSites: Array<number>;
	modalHeaderTitle: string;

	readonly P2P_FIELDS = {
		SITE_A: 'SITE_A',
		SITE_Z: 'SITE_Z'
	};

	readonly CONTACT_TYPE_ENUM: any = LOOKUP_ENUM.CONTACT_TYPE;
	readonly SUBCLIENT_LOOKUP_MODEL: string = LOOKUP_MODELS_ENUM.SUBCLIENT.modelName;
	tempBanName: string;
	selectedSubclientId: number;
	subclientSubscribe: Subscription;
	initialAccountName: string;
	selectedBan: string;
	currency: any;

	constructor(
		public alertService: AlertService,
		public formBuilder: FormBuilder,
		public dialogService: DialogService,
		public tabService: TabService,
		public siteService: SiteService,
		public inventoryService: InventoryService,
		public dialogRef: MatDialogRef<OrderServiceManageDialogComponent>,
		public orderServiceService: OrderServiceService,
		public networkHubService: NetworkHubService,
		public demarcService: DemarcService,
		public configService: ConfigService,
		public orderServiceFlowService: OrderServiceFlowService
	) {
		super(dialogService, dialogRef);
		this.order = this.order ? this.order : new Order();
		this.orderService = this.orderService ? this.orderService : new OrderService();

		this.isUpdate = !!this.orderService.id;

		this.orderService.inventory = this.orderService.inventory ? this.orderService.inventory : new Inventory();
		this.orderService.inventory.account = this.orderService.inventory.account ? this.orderService.inventory.account : new Account();
		this.orderService.inventory.account.subclient = this.orderService.inventory.account.subclient ? this.orderService.inventory.account.subclient : new LookupModel();
		this.technologyId = this.orderService.inventory.technology_id;
		this.messages = ResourcesService.messages(this.MESSAGES_MODULE);
		this.demarc_messages = ResourcesService.messages(this.MESSAGES_DEMARC_MODULE);
	}

	toggleReqFields() {
		if (this.onlyRequiredCustomFields) {
			this.onlyRequiredCustomFields = false;
		} else {
			this.onlyRequiredCustomFields = true;
		}
	}

	toggleReqFields1() {
		if (this.onlyRequiredCustomFields1) {
			this.onlyRequiredCustomFields1 = false;
		} else {
			this.onlyRequiredCustomFields1 = true;
		}
	}

	toggleReqFields2() {
		if (this.onlyRequiredCustomFields2) {
			this.onlyRequiredCustomFields2 = false;
		} else {
			this.onlyRequiredCustomFields2 = true;
		}
	}

	toggleReqFields3() {
		if (this.onlyRequiredCustomFields3) {
			this.onlyRequiredCustomFields3 = false;
		} else {
			this.onlyRequiredCustomFields3 = true;
		}
	}

	ngOnInit() {
		if (this.order) this.currency = this.order.currency_obj ? this.order.currency_obj.currency : null;

		this.configService.findAll().subscribe((config: Config) => {
			this.minimumTermMonths = config.order.minimum_term_months;
			this.maximumTermMonths = config.order.maximum_term_months;
		});

		this.tabGroup = this.tabService.create();

		if (this.orderService.inventory.site_a_id) {
			this.loadSiteADemarcs(this.orderService.inventory.site_a_id);
		}
		if (this.orderService.inventory.site_z_id) {
			this.loadSiteZDemarcs(this.orderService.inventory.site_z_id);
		}

		this.orderService = this.orderService || new OrderService();
		this.orderService.inventory.topology_id = this.INVENTORY_TOPOLOGY.POINT_TO_POINT;
		this.isUpdate = !!this.orderService.id;
		this.initilizeCustomAttributes();
		this.orderService.inventory.site_id = null;
		//Enter here only when click on edit order service
		if (this.orderService.inventory.ban) {
			this.selectedSubclientId = -1;
		}
		//Enter here only if account has subclinet id
		if (this.orderService.inventory && this.orderService.inventory.account && this.orderService.inventory.account['subclient'] && this.orderService.inventory.account['subclient'].id) {
			this.selectedSubclientId = this.orderService.inventory.account['subclient'].id;
		}
		this.initialAccountName = this.orderService.inventory.ban || null;
		let formControls = {
			des_due_date: new FormControl(this.orderService && this.orderService.des_due_date ? new Date(this.orderService.des_due_date) : null, Validators.required),
			description: new FormControl(this.orderService.description),
			expedited_order: new FormControl(this.orderService.expedited_order),
			order_number: new FormControl(this.orderService.order_number),
			purchase_number: new FormControl(this.orderService.purchase_number),
			// subclient_id: new FormControl(this.orderService.inventory.ban, Validators.required),
			inventory: this.formBuilder.group({
				state: new FormControl(this.orderService.state),
				disconnect_state: new FormControl(this.orderService.disconnect_state),
				customer_circuit_id: new FormControl(this.orderService.inventory.customer_circuit_id),
				ban: new FormControl(this.orderService.inventory.ban, Validators.required),
				subclient_id: new FormControl(this.selectedSubclientId, Validators.required),
				sp_ckt_id: new FormControl(this.orderService.inventory.sp_ckt_id, Validators.pattern('^[a-z0-9A-Z_]+')),
				handoff_type_id: new FormControl(this.orderService.inventory.handoff_type_id),
				customer_id: new FormControl(this.orderService.inventory.customer_id),
				bw_max_id: new FormControl(this.orderService.inventory.bw_max_id),
				bw_model_id: new FormControl(this.orderService.inventory.bw_model_id),
				cir_bw_id: new FormControl(this.orderService.inventory.cir_bw_id),
				ckt_usage_id: new FormControl(this.orderService.inventory.ckt_usage_id),
				ckt_type_id: new FormControl(this.orderService.inventory.ckt_type_id),
				contract_id: new FormControl(this.orderService.inventory.contract_id),
				term: new FormControl(this.orderService.inventory.term),
				exp_date: new FormControl(this.orderService && this.orderService.inventory.exp_date ? new Date(this.orderService.inventory.exp_date) : null),
				est_mrc: new FormControl(this.orderService.inventory.est_mrc),
				est_nrc: new FormControl(this.orderService.inventory.est_nrc),
				topology_id: new FormControl(this.orderService.inventory.topology_id, Validators.required),
				technology_id: new FormControl(this.orderService.inventory.technology_id),
				protected_id: new FormControl(this.orderService.inventory.protected_id),
				prim_failover_id: new FormControl(this.orderService.inventory.prim_failover_id),
				site_id: new FormControl(this.orderService.inventory.site_id),
				site_lcontact_id: new FormControl(this.orderService.inventory.site_lcontact_id),
				site_a_id: new FormControl(this.orderService.inventory.site_a_id),
				site_z_id: new FormControl(this.orderService.inventory.site_z_id),
				site_a_lcontact_id: new FormControl(this.orderService.inventory.site_a_lcontact_id),
				site_z_lcontact_id: new FormControl(this.orderService.inventory.site_z_lcontact_id),
				network_hub_a: new FormControl(),
				network_hub_circuit_a: new FormControl(),
				network_hub_circuit_child_a: new FormControl(),
				vlan_id: new FormControl(this.orderService.inventory.vlan_id),
				site_a_demarc: this.formBuilder.group({
					row_id: new FormControl(''),
					rack_id: new FormControl(''),
					shelf_id: new FormControl(''),
					cpe_id: new FormControl(''),
					card_id: new FormControl(''),
					port_id: new FormControl(''),
					extended: new FormControl(false),
					notes: new FormControl('')
				}),
				site_z_demarc: this.formBuilder.group({
					row_id: new FormControl(''),
					rack_id: new FormControl(''),
					shelf_id: new FormControl(''),
					cpe_id: new FormControl(''),
					card_id: new FormControl(''),
					port_id: new FormControl(''),
					extended: new FormControl(false),
					notes: new FormControl('')
				})
			}),
			contacts: new FormControl('')
		};

		if (this.orderService.inventory.network_hub_map) {
			formControls.inventory.controls.network_hub_a.setValue(this.orderService.inventory.network_hub_map.network_hub_id);
			if (this.orderService.inventory.network_hub_map.name === 'DS1') {
				formControls.inventory.controls.network_hub_circuit_a.setValue(this.orderService.inventory.network_hub_map.parent_id.toString());
				formControls.inventory.controls.network_hub_circuit_child_a.setValue(this.orderService.inventory.network_hub_map.id);
			} else if (this.orderService.inventory.network_hub_map.name === 'DS3') {
				formControls.inventory.controls.network_hub_circuit_a.setValue(this.orderService.inventory.network_hub_map.id);
				formControls.inventory.controls.network_hub_circuit_child_a.setValue(null);
			} else {
				formControls.inventory.controls.network_hub_circuit_a.setValue(null);
				formControls.inventory.controls.network_hub_circuit_child_a.setValue(null);
			}

			if (this.orderService.inventory.network_hub_map.network_hub && this.orderService.inventory.network_hub_map.network_hub.hub_type_id === this.NETWORK_HUB_TYPE.TDM) {
				this.hub_labels.level_1 = 'DS3';
				this.hub_labels.level_2 = 'DS1';
			}
		}

		this.configService.loadCustomFields().subscribe((config) => {
			this.custom_fields = config.custom_values.order_service_summary;
			let cus_val = this.orderService.custom_values && this.isUpdate ? this.orderService.custom_values : {};
			for (let i = 0; i < this.custom_fields.length; i++) {
				if (!this.categories.includes(this.custom_fields[i].category)) {
					this.categories.push(this.custom_fields[i].category);
				}
				this.custom_fields[i].value = cus_val[this.custom_fields[i].field_name];
				this.form.addControl(this.custom_fields[i].field_name, new FormControl(this.custom_fields[i].value));
			}
			// this.categories.sort();
			if (this.categories && this.categories.length === 0) {
				this.onlyRequiredCustomFields = false;
			}
		});

		this.configService.loadCustomFields().subscribe((config) => {
			this.custom_fields1 = config.custom_values.order_service_financial_contract;
			let cus_val = this.orderService.custom_values && this.isUpdate ? this.orderService.custom_values : {};
			for (let i = 0; i < this.custom_fields1.length; i++) {
				if (!this.categories1.includes(this.custom_fields1[i].category)) {
					this.categories1.push(this.custom_fields1[i].category);
				}
				this.custom_fields1[i].value = cus_val[this.custom_fields1[i].field_name];
				this.form.addControl(this.custom_fields1[i].field_name, new FormControl(this.custom_fields1[i].value));
			}
			// this.categories1.sort();
			if (this.categories1 && this.categories1.length === 0) {
				this.onlyRequiredCustomFields1 = false;
			}
		});

		this.configService.loadCustomFields().subscribe((config) => {
			this.custom_fields2 = config.custom_values.order_service_configuration;
			let canEditFields;
			let orderServiceState;
			if (this.order.type_id === ORDER_TYPE.DISCONNECT) {
				canEditFields = [ORDER_SERVICE_DISCONNECT_STATUS.NEW, ORDER_SERVICE_DISCONNECT_STATUS.NEW_RESET, ORDER_SERVICE_DISCONNECT_STATUS.NEW_REJECTED];
				orderServiceState = this.orderService.disconnect_state;
			} else {
				canEditFields = [ORDER_SERVICE_STATUS.NEW, ORDER_SERVICE_STATUS.NEW_RESET, ORDER_SERVICE_STATUS.NEW_REJECTED];
				orderServiceState = this.orderService.state;
			}
			let cus_val = this.orderService.custom_values && this.isUpdate ? this.orderService.custom_values : {};
			for (let i = 0; i < this.custom_fields2.length; i++) {
				if (!this.categories2.includes(this.custom_fields2[i].category)) {
					this.categories2.push(this.custom_fields2[i].category);
				}
				this.custom_fields2[i].value = cus_val[this.custom_fields2[i].field_name];
				if (!this.isUpdate || canEditFields.includes(orderServiceState)) {
					this.form.addControl(this.custom_fields2[i].field_name, new FormControl(this.custom_fields2[i].value));
				} else {
					this.form.addControl(this.custom_fields2[i].field_name, new FormControl({ value: this.custom_fields2[i].value, disabled: true }));
				}
			}
			// this.categories2.sort();
			if (this.categories2 && this.categories2.length === 0) {
				this.onlyRequiredCustomFields2 = false;
			}
		});

		this.configService.loadCustomFields().subscribe((config) => {
			this.custom_fields3 = config.custom_values.order_service_site_a_z;
			let cus_val = this.orderService.custom_values && this.isUpdate ? this.orderService.custom_values : {};
			for (let i = 0; i < this.custom_fields3.length; i++) {
				if (!this.categories3.includes(this.custom_fields3[i].category)) {
					this.categories3.push(this.custom_fields3[i].category);
				}
				this.custom_fields3[i].value = cus_val[this.custom_fields3[i].field_name];
				this.form.addControl(this.custom_fields3[i].field_name, new FormControl(this.custom_fields3[i].value));
			}
			// this.categories3.sort();
			if (this.categories3 && this.categories3.length === 0) {
				this.onlyRequiredCustomFields3 = false;
			}
		});

		this.form = this.formBuilder.group(formControls);

		combineLatest([
			this.form.get('inventory').get('site_a_id').valueChanges.pipe(startWith(this.form.value.inventory.site_a_id)),
			this.form.get('inventory').get('site_z_id').valueChanges.pipe(startWith(this.form.value.inventory.site_z_id))
		])
			.pipe(takeUntil(this.destroy$))
			.subscribe((selectedSites: Array<number>) => {
				this.disabledSites = selectedSites;
			});

		if (this.isUpdate) {
			this.loadNetworkHubHierarchy();

			this.form.controls.inventory['controls'].sp_ckt_id.required = true;
		}
		if (this.order.type_id === ORDER_TYPE.DISCONNECT || this.order.type_id === ORDER_TYPE.CHANGE) {
			this.modalHeaderTitle = 'Add Circuit Service';
		} else {
			this.modalHeaderTitle = 'Add Service';
		}
		this.order.type_id;
		this.formTitle = this.isUpdate ? `Edit ${this.orderService.full_service_id}` : `${this.modalHeaderTitle}`;
		this.inventorySites = this.orderService.inventory.inventory_sites || [];
		this.internal_id = this.orderService.inventory.internal_id || null;

		if (this.orderService.inventory.hub_a && this.orderService.inventory.hub_a.hub_type_id === this.NETWORK_HUB_TYPE.TDM) {
			this.hub_labels.level_1 = 'DS3';
			this.hub_labels.level_2 = 'DS1';
		}

		this.form.controls.inventory['controls'].network_hub_a.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
			let capacityHubA;
			if (this.hubPickerA) {
				let selectedHub = this.hubPickerA.entries.filter((item) => item.id === value);
				if (selectedHub && selectedHub.hub_type_id === this.NETWORK_HUB_TYPE.TDM) {
					this.hub_labels.level_1 = 'DS3';
					this.hub_labels.level_2 = 'DS1';
				} else {
					this.hub_labels = {
						level_1: 'Level 1',
						level_2: 'Level 2'
					};
				}
				if (selectedHub && selectedHub[0]) {
					capacityHubA = selectedHub[0].hub_capacity_id;
					// if (capacityHubA == this.NETWORK_HUB_CAPACITY.DS_3 || capacityHubA == this.NETWORK_HUB_CAPACITY.OC_12) {
					this.form.controls.inventory['controls'].network_hub_circuit_a.setValue(null);
					this.form.controls.inventory['controls'].network_hub_circuit_child_a.setValue(null);
					// }
				}
			}
		});

		this.form.controls.inventory['controls'].network_hub_circuit_a.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((result) => {
			this.form.controls.inventory['controls'].network_hub_circuit_child_a.setValue(null);
		});

		this.form.controls.inventory['controls'].term.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((res) => {
			/* termHasError */
			const termForm = this.form.get(['inventory', 'term']);
			if (termForm.value !== null) {
				if (termForm.hasError('required') || termForm.hasError('range') || termForm.hasError('pattern')) {
					this.termHasError = true;
				} else {
					this.termHasError = false;
				}
			} else {
				this.termHasError = false;
			}
		});

		this.loadTabs();

		if (this.isUpdate && this.form.controls.inventory['controls'].topology_id.value !== this.orderService.inventory.topology_id) {
			this.form.controls.inventory['controls'].topology_id.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
				this.resetFormControlValues(<FormGroup>this.form.controls.inventory, ['site_id', 'site_lcontact_id', 'site_a_id', 'site_z_id', 'site_a_lcontact_id', 'site_z_lcontact_id']);
			});
		}

		this.form.controls.inventory['controls'].site_a_id.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((siteAId) => {
			if (!siteAId) {
				return (this.siteADemarcs = []);
			}
			this.loadSiteADemarcs(siteAId);
		});

		this.form.controls.inventory['controls'].site_z_id.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((siteZId) => {
			if (!siteZId) {
				return (this.siteZDemarcs = []);
			}
			this.loadSiteZDemarcs(siteZId);
		});

		if (
			this.isUpdate &&
			(this.orderService.state >= this.ORDER_SERVICE_STATUS.RFA ||
				(this.orderService.disconnect_state >= this.ORDER_SERVICE_DISCONNECT_STATUS.VENDOR_ACCEPTED && this.orderService.disconnect_state <= this.ORDER_SERVICE_DISCONNECT_STATUS.COMPLETED))
		) {
			this.passedRfa = true;
		}

		/* Lock after RFA */
		if (this.passedRfa) {
			this.form.controls.inventory['controls'].vlan_id.disable();
			this.form.controls.inventory['controls'].term.disable();
			this.form.controls.description.disable();

			// let innerFormGroups = ['inventory', 'site_a_demarc', 'site_z_demarc'];
			// let excludedFields = ['order_number', 'purchase_number', 'sp_ckt_id','vlan_id','term'];
			// for (let control in this.form.controls) {
			//   if (innerFormGroups.indexOf(control) === -1) {
			//     if (excludedFields.indexOf(control) === -1) {
			//       this.form.controls[control].disable();
			//     }
			//   }
			//   else {
			//     for (let innerControl in this.form.controls[control]['controls']) {
			//       if (innerControl !== 'sp_ckt_id') {
			//         this.form.controls[control]['controls'][innerControl].disable();
			//       }
			//     }
			//   }
			// }
		}

		// When subclient_id changed.
		this.subclientSubscribe = this.form.get(['inventory', 'subclient_id']).valueChanges.subscribe((item) => {
			// Get selected subclient_id
			this.selectedSubclientId = item;
			// set BAN to null ONLY when no subclient is selected OR when sulected subclient doesn't match selected BAN
			if (
				!this.selectedBan ||
				(item !== -1 && this.selectedBan && this.selectedBan['subclient_id'] !== item) ||
				(item == -1 && this.selectedBan && this.selectedBan['subclient_id'] !== 0) ||
				item == 0
			) {
				this.form.controls['inventory'].patchValue({ ban: null });
			}

			if (!this.isUpdate) {
				let subclient = item;
				if (item === -1) {
					subclient = 0;
				}
				// tempBan should only be created if subclient is selected
				if (subclient != null) {
					let vendorWihtZero = this.order.vendor ? this.order.vendor.id.toString().padStart(4, '0') : null;
					let subclientIdWithZero = subclient.toString().padStart(4, '0');
					this.tempBanName = `TEMP${subclientIdWithZero}${vendorWihtZero}`;
				} else this.tempBanName = null;
			}
		});
	}

	// Workaround for angular component issue #13870
	disableAnimation = true;
	ngAfterViewInit(): void {
		// timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
		setTimeout(() => (this.disableAnimation = false));
	}

	init() {
		if (this.orderService && this.orderService.id) {
			return this.orderServiceService
				.findByIdForEdit(
					this.order.id,
					new EntityEditContext({
						dialogRef: this.dialogRef
					})
				)
				.pipe(
					flatMap((order) => {
						this.orderService = order;
						return observableOf(order);
					})
				);
		}
	}

	loadNetworkHubHierarchy() {
		if (this.orderService.inventory.network_hub_map && this.orderService.inventory.network_hub_map['network_hub_id']) {
			this.networkHubService.fetchHubCircuits(this.orderService.inventory.network_hub_map['network_hub_id']).subscribe(
				(networkHubHierarchy) => {
					let networkHubId;
					let circuits = JSON.parse(JSON.stringify(networkHubHierarchy));
					let filteredCircuits = circuits.filter((circuit) => {
						if (circuit.inventory_id !== null) {
							return circuit;
						}
					});
					filteredCircuits.forEach((circuit) => {
						if (!circuit.parent_id) {
							networkHubId = parseInt(circuit.id, null);

							filteredCircuits.forEach((item) => {
								if (item.parent_id === networkHubId && item.inventory_id === this.orderService.inventory_id) {
									this.level1 = item;
								} else {
									let sub_item = item;

									filteredCircuits.forEach((level1temp) => {
										if (sub_item.parent_id === parseInt(level1temp.id, null) && sub_item.parent_id !== networkHubId && sub_item.inventory_id === this.orderService.inventory_id) {
											this.level1 = level1temp;
											this.level2 = sub_item;
										}
									});
								}
							});
						}
					});
				},
				(error) => {
					this.alertService.error('', this.messages.get('FETCH_HUB_RELATED_CIRCUITS'));
				}
			);
		}
	}

	public onTabChange(index) {
		let tab = this.tabGroup.tabs[index];
		if (!tab.disabled) {
			this.activeTabIndex = index;
			this.tabGroup.activate(tab.key);
		}
	}

	public loadTabs() {
		this.oderTypeRequiredFieldFunc();
		this.customAtt = this.order.vendor && this.order.vendor.order_fields_schema && this.order.vendor.order_fields_schema.length > 0;
		/*let sitesTitle = this.orderService.inventory.topology_id === this.INVENTORY_TOPOLOGY.RING ? "Sites" : "Site A/Z";*/
		let sitesTitle = 'Site A/Z';

		this.tabGroup.addTab({ key: 1, title: 'Summary' });
		this.tabGroup.addTab({ key: 2, title: 'Financial' });
		this.tabGroup.addTab({ key: 3, title: 'Configuration' });
		this.tabGroup.addTab({ key: 4, title: sitesTitle });
		this.tabGroup.addTab({ key: 5, title: 'Contacts' });

		this.tabGroup.activate(1);

		this.tabGroup.onActivate.subscribe((tab) => {
			setTimeout(() => {
				this.tabChanged = !this.tabChanged;
			});
		});
	}

	public gethubType(hubId, hubPicker) {
		if (hubPicker && hubId) {
			let selectedHub = hubPicker.entries.filter((item) => item.id === hubId);
			if (selectedHub && selectedHub[0]) {
				return selectedHub[0].hub_capacity_id;
			}
		} else {
			return null;
		}
	}

	public isHubPickerVisible(hubId, level, hubPicker) {
		if (hubPicker && hubId) {
			let selectedHub = hubPicker.entries.filter((item) => item.id === hubId);
			if (selectedHub && selectedHub[0]) {
				let capacity = selectedHub[0].hub_capacity_id;
				switch (capacity) {
					case this.NETWORK_HUB_CAPACITY.DS_3:
						return level === this.HUB_PICKER_LEVEL.LEVEL_2;
					case this.NETWORK_HUB_CAPACITY.OC_12:
						return true;
					default:
						return false;
				}
			}
		} else {
			return false;
		}
	}

	updateNetworkHub(inventoryId, inventoryNetworkHub, inventoryCircuitChild, hubPicker, network_hub_circuit_a) {
		if (hubPicker) {
			let capacityHub;
			let selectedHub = hubPicker.entries.filter((item) => item.id === inventoryNetworkHub);
			if (selectedHub && selectedHub[0]) {
				capacityHub = selectedHub[0].hub_capacity_id;
				if (capacityHub === this.NETWORK_HUB_CAPACITY.DS_3 || capacityHub === this.NETWORK_HUB_CAPACITY.OC_12) {
					if (inventoryCircuitChild) {
						this.networkHubService.updateHubCircuit(inventoryCircuitChild, { inventory_id: inventoryId });
					} else {
						this.networkHubService.createHubCircuit({
							inventory_id: inventoryId,
							name: 'DS1',
							network_hub_id: inventoryNetworkHub,
							parent_id: network_hub_circuit_a
						});
					}
				} else {
					this.networkHubService.createHubCircuit({
						inventory_id: inventoryId,
						network_hub_id: inventoryNetworkHub
					});
				}
			}
		}
	}

	onSubmit({ value, valid }: { value: OrderService; valid: boolean }): void {
		this.prepared_fields = {};
		for (let i = 0; i < this.custom_fields.length; i++) {
			if (this.custom_fields[i].field_name in value) {
				this.prepared_fields[this.custom_fields[i].field_name] = value[this.custom_fields[i].field_name];
			}
		}
		for (let i = 0; i < this.custom_fields1.length; i++) {
			if (this.custom_fields1[i].field_name in value) {
				this.prepared_fields[this.custom_fields1[i].field_name] = value[this.custom_fields1[i].field_name];
			}
		}
		for (let i = 0; i < this.custom_fields2.length; i++) {
			if (this.custom_fields2[i].field_name in value) {
				this.prepared_fields[this.custom_fields2[i].field_name] = value[this.custom_fields2[i].field_name];
			}
		}
		for (let i = 0; i < this.custom_fields3.length; i++) {
			if (this.custom_fields3[i].field_name in value) {
				this.prepared_fields[this.custom_fields3[i].field_name] = value[this.custom_fields3[i].field_name];
			}
		}
		value.custom_values = this.prepared_fields;
		if (this.orderService && this.orderService.id) {
			if (this.orderService.state === this.ORDER_SERVICE_STATUS.NEW_PENDING && this.order.type_id !== this.ORDER_TYPE.DISCONNECT) {
				value.state = this.ORDER_SERVICE_STATUS.NEW;
				value['isChangedToNew'] = true;
			} else if (this.orderService.disconnect_state === this.ORDER_SERVICE_DISCONNECT_STATUS.NEW_PENDING && this.order.type_id === this.ORDER_TYPE.DISCONNECT) {
				value.disconnect_state = this.ORDER_SERVICE_DISCONNECT_STATUS.NEW;
				value['isChangedToNew'] = true;
			}
			this.update(Object.assign({}, this.orderService, value));
		} else {
			this.create(value);
		}
	}

	create(orderService: OrderService) {
		let orderSvc = <any>orderService;
		orderSvc.state = this.ORDER_SERVICE_STATUS.NEW;
		orderSvc.order_id = this.order.id;
		orderSvc.order_type = this.order.type_id;
		orderSvc.inventory.vendor_id = this.order.vendor_id;
		orderSvc.inventory.type_id = LOOKUP_ENUM.INVENTORY_TYPE.CIRCUIT;
		orderSvc.inventory.est_mrc = this.unmask(orderSvc.inventory.est_mrc);
		orderSvc.inventory.est_nrc = this.unmask(orderSvc.inventory.est_nrc);
		orderSvc.inventory.currency_id = this.order.currency_id;

		this.toggleDialogButtons();

		this.orderServiceService.create(orderSvc).subscribe(
			(result) => {
				if (result.error) {
					this.toggleDialogButtons(false);
					this.alertService.error('', result.error.message == 'Duplicate unique id' ? this.messages.get('DUPLICATE_VENDOR_ACCOUNT_SP_CKT_ID') : result.error.message);
				} else {
					this.insertSites(result.inventory.id);
					result.inventory.inventory_sites = this.inventorySites;

					if (result.inventory.topology_id === this.INVENTORY_TOPOLOGY.POINT_TO_POINT) {
						/* this.insertDemarcs(result.inventory.id); */
						this.insertDemarcsTmp(result.inventory.id, result.inventory.site_a_id, result.inventory.site_z_id);
					}
					this.updateNetworkHub(result.inventory.id, result.inventory.network_hub_a, result.inventory.network_hub_circuit_child_a, this.hubPickerA, result.inventory.network_hub_circuit_a);

					this.closeDialog(result, true);
				}
			},
			(error) => {
				this.toggleDialogButtons(false);
				this.alertService.error('', this.messages.get('ORDER_SERVICE_CREATE_ERROR'));
			}
		);
	}

	insertDemarcsTmp(inventoryId, siteAId, siteZId) {
		this.siteADemarcTmp.site_id = siteAId;
		let siteADemarcForm = this.form.get('inventory').get('site_a_demarc')['controls'];
		Object.keys(siteADemarcForm).forEach((key) => {
			this.siteADemarcTmp[key] = siteADemarcForm[key].value;
		});
		this.siteADemarcs = [this.siteADemarcTmp];

		this.siteZDemarcTmp.site_id = siteZId;
		let siteZDemarcForm = this.form.get('inventory').get('site_z_demarc')['controls'];
		Object.keys(siteZDemarcForm).forEach((key) => {
			this.siteZDemarcTmp[key] = siteZDemarcForm[key].value;
		});
		this.siteZDemarcs = [this.siteZDemarcTmp];

		let unsavedDemarcs = this.siteADemarcs
			.filter((item) => {
				return item.unsaved && !item.id && item.site_id;
			})
			.concat(
				this.siteZDemarcs.filter((item) => {
					return item.unsaved && !item.id && item.site_id;
				})
			);

		let demarcsToUpdate = this.siteADemarcs
			.filter((item) => {
				return item.id && item.site_id;
			})
			.concat(
				this.siteZDemarcs.filter((item) => {
					return item.id && item.site_id;
				})
			);

		unsavedDemarcs.forEach((demarc) => {
			demarc.network_circuit_id = inventoryId;
			this.demarcService.create(demarc).subscribe(
				(result) => {},
				(err) => {
					console.log('Error save demarcs');
				}
			);
		});

		demarcsToUpdate.forEach((demarc) => {
			demarc.network_circuit_id = inventoryId;
			this.demarcService.update(demarc.id, demarc).subscribe(
				(result) => {},
				(err) => {
					console.log('Error update demarcs');
				}
			);
		});
	}

	update(orderService: OrderService) {
		let orderSvc = <any>orderService;
		orderSvc.inventory.vendor_id = this.order.vendor_id;
		orderSvc.inventory.est_mrc = this.unmask(orderSvc.inventory.est_mrc);
		orderSvc.inventory.est_nrc = this.unmask(orderSvc.inventory.est_nrc);
		orderSvc.inventory.internal_id = this.internal_id;
		this.toggleDialogButtons();
		this.orderServiceService.update(orderSvc.id, orderSvc).subscribe(
			(result) => {
				if (result.error) {
					this.toggleDialogButtons(false);
					this.alertService.error('', result.error.message == 'Duplicate unique id' ? this.messages.get('DUPLICATE_VENDOR_ACCOUNT_SP_CKT_ID') : result.error.message);
				} else {
					this.insertSites(result.inventory.id);
					result.inventory.inventory_sites = this.inventorySites;
					if (result.inventory.topology_id === this.INVENTORY_TOPOLOGY.POINT_TO_POINT) {
						/* this.insertDemarcs(result.inventory.id); */
						this.insertDemarcsTmp(result.inventory.id, result.inventory.site_a_id, result.inventory.site_z_id);
					}
					this.updateNetworkHub(result.inventory.id, result.inventory.network_hub_a, result.inventory.network_hub_circuit_child_a, this.hubPickerA, result.inventory.network_hub_circuit_a);

					this.closeDialog(result, true);
				}
			},
			(error) => {
				this.toggleDialogButtons(false);
				this.alertService.error('', this.messages.get('ORDER_SERVICE_CREATE_ERROR'));
			}
		);
	}

	deleteOrderService(event) {
		const updateInventoryId = this.orderService && this.orderService.inventory ? this.orderService.inventory.id : null;
		const canBeDeleted =
			this.orderService.state === this.ORDER_SERVICE_STATUS.NEW_PENDING ||
			this.orderService.state === this.ORDER_SERVICE_STATUS.NEW ||
			this.orderService.state === this.ORDER_SERVICE_STATUS.NEW_RESET ||
			this.orderService.state === this.ORDER_SERVICE_STATUS.NEW_REJECTED ||
			this.orderService.state === this.ORDER_SERVICE_STATUS.RFA
				? true
				: false;

		const alertCantBeDeleted = () => {
			const message = this.order.type_id === 10 ? this.messages.get('ORDER_SERVICE_DELETE_NOT_NEW_RFA') : this.messages.get('ORDER_SERVICE_DELETE_NOT_NEW');
			this.alertService.error('', message);
		};

		const services = this.order.services.filter((service) => service.id !== this.orderService.id);
		const proceedWithDeletion = () => {
			if (this.orderService && this.orderService.id && event) {
				this.toggleDialogButtons();
				this.orderServiceService.delete(this.orderService.id).subscribe(
					(result) => {
						if (!services.length) {
							this.orderServiceFlowService.resetOrderFlow(this.order.id);
						}
						if (updateInventoryId) {
							this.inventoryService
								.findByIdForEdit(updateInventoryId)
								.pipe(
									mergeMap((inventory: Inventory) => {
										inventory.status_id = LOOKUP_ENUM.INVENTORY_STATUS.ACTIVE;
										inventory.disc_date = null;
										this.inventoryService.addRecordAfterOSDelete(inventory.id, inventory, this.orderService.full_service_id);
										return this.inventoryService.update(inventory.id, inventory);
									})
								)
								.subscribe(
									(result) => {
										this.closeDialog({}, true);
									},
									(error) => {
										this.alertService.error('', this.messages.get('UPDATE_ERROR'));
									}
								);
						}
						this.dialogRef.close(true);
					},
					(err) => {
						this.toggleDialogButtons(false);
						this.alertService.error('', this.messages.get('DELETE_ERROR'));
					}
				);
			}
		};

		canBeDeleted ? proceedWithDeletion() : alertCantBeDeleted();
	}

	public unmask(value) {
		if (value && typeof value == 'number') return value;
		else if (value) return Number(value.replace(/[^0-9\.]+/g, ''));
	}

	public initilizeCustomAttributes() {
		// this.fields = [];
		// let schema = this.order.vendor.order_fields_schema || [];
		// let values = this.orderService.custom_values;
		//
		// schema.forEach((entry) => {
		//   let field = {
		//     name: entry.field,
		//     value: null
		//   }
		//
		//   let value = this.findValue(values, entry.field);
		//   if (value) {
		//     field.value = value.value;
		//   }
		//   this.fields.push(field);
		// });
	}

	public findValue(values, name) {
		values = values || [];
		return values.filter((val) => {
			return val.name === name;
		})[0];
	}

	deleteSite(site_id, siteName) {
		this.dialogService
			.confirm({
				bodyText: `Delete site ${siteName}`
			})
			.afterClosed()
			.subscribe((result) => {
				if (result && this.orderService.inventory.id) {
					this.inventoryService.deleteSite(this.orderService.inventory.id, site_id).subscribe(
						() => {
							this.filterSiteFromList(site_id);
						},
						() => {
							this.filterSiteFromList(site_id);
						}
					);
				}
				this.filterSiteFromList(site_id);
			});
	}

	filterSiteFromList(site_id) {
		this.inventorySites = this.inventorySites.filter((elem) => {
			return elem.site.id !== site_id;
		});
	}

	changedTopology(changes) {
		/*let sitesTitle = changes.value === this.INVENTORY_TOPOLOGY.RING ? "Sites" : "Site A/Z";*/
		let sitesTitle = 'Site A/Z';

		this.inventorySites = [];
		this.orderService.inventory.site_id = null;
		this.orderService.inventory.site_lcontact_id = null;

		this.orderService.inventory.site_a_id = null;
		this.orderService.inventory.site_z_id = null;
		this.orderService.inventory.site_a_lcontact_id = null;
		this.orderService.inventory.site_z_lcontact_id = null;

		this.tabGroup.tabs[3].title = sitesTitle;
	}

	public isSitesTabDisabled(value) {
		this.topology = value;
		return !value;
	}

	addSite(site_id, contact_id) {
		if (this.ifSiteExist(site_id)) {
			this.alertService.error('', this.messages.get('SITE_EXIST_ERROR'));
		} else {
			this.siteService.findById(site_id).subscribe((res) => {
				this.inventorySites.push({ site: res, siteId: site_id, contactId: contact_id, new: true });
				this.orderService.inventory.site_id = null;
				this.orderService.inventory.site_lcontact_id = null;
			});
		}
	}

	public insertSites(inventoryId) {
		let sites = this.inventorySites.filter((item) => {
			return item.new === true;
		});

		sites.forEach((element) => {
			this.inventoryService.addSite(inventoryId, element.siteId, element.contactId).subscribe(
				() => {},
				() => {
					this.alertService.error('', this.messages.get('ORDER_SERVICE_SITE_UPDATE_ERROR'));
				}
			);
		});

		this.orderService.inventory.inventory_sites = this.inventorySites;
	}

	public ifSiteExist(site_id) {
		let sites = this.inventorySites;

		let siteExists = false;

		if (
			sites &&
			sites.filter((item) => {
				return item.siteId === site_id || item.site_id === site_id;
			}).length
		) {
			siteExists = true;
		}

		return siteExists;
	}

	resetFormControlValues(form: FormGroup, formControlNames: Array<string>) {
		formControlNames.map((formControlName) => {
			if (form.controls[formControlName]) {
				form.controls[formControlName].reset();
			}
		});
	}

	/* Demarcs related */

	/* Site A demarcs */
	loadSiteADemarcs(siteId: number) {
		if (this.orderService.inventory && this.orderService.inventory.id && siteId) {
			this.demarcService.getDemarcs(siteId, this.orderService.inventory.id).subscribe(
				(results) => {
					this.siteADemarcs = results.items;

					this.siteADemarcTmp = this.siteADemarcs[0] || new Demarc({ unsaved: true });

					this.form.get('inventory').get('site_a_demarc')['controls']['row_id'].patchValue(this.siteADemarcTmp.row_id);
					this.form.get('inventory').get('site_a_demarc')['controls']['rack_id'].patchValue(this.siteADemarcTmp.rack_id);
					this.form.get('inventory').get('site_a_demarc')['controls']['shelf_id'].patchValue(this.siteADemarcTmp.shelf_id);
					this.form.get('inventory').get('site_a_demarc')['controls']['cpe_id'].patchValue(this.siteADemarcTmp.cpe_id);
					this.form.get('inventory').get('site_a_demarc')['controls']['card_id'].patchValue(this.siteADemarcTmp.card_id);
					this.form.get('inventory').get('site_a_demarc')['controls']['notes'].patchValue(this.siteADemarcTmp.notes);
					this.form.get('inventory').get('site_a_demarc')['controls']['extended'].patchValue(this.siteADemarcTmp.extended);
					this.form.get('inventory').get('site_a_demarc')['controls']['port_id'].patchValue(this.siteADemarcTmp.port_id);
				},
				(err) => {
					console.log('Error loading Site A Demarcs');
				}
			);
		}
	}

	onSiteADemarcAddRequested(event) {
		this.selectedSiteADemarc = null;
		this.siteADemarcFormMode = true;
	}

	onSiteADemarcEditRequested(demarc: Demarc) {
		this.selectedSiteADemarc = demarc;
		this.siteADemarcFormMode = true;
	}

	onSiteADemarcDeleteRequested(demarc: Demarc) {
		if (demarc.id && !demarc.unsaved) {
			this.demarcService.delete(demarc.id).subscribe(
				() => {},
				() => {
					console.log('Error delete demarc');
				}
			);
		}
		this.siteADemarcs = this.siteADemarcs.filter((item) => {
			return item !== demarc;
		});
	}

	onSiteADemarcSaveRequested(demarc: Demarc) {
		if (this.selectedSiteADemarc) {
			Object.assign(this.selectedSiteADemarc, demarc);
		} else {
			this.siteADemarcs = this.siteADemarcs || [];
			this.siteADemarcs.push(demarc);
		}
		this.selectedSiteADemarc = null;
		this.siteADemarcFormMode = false;
	}

	onSiteADemarcFormCancelled(event) {
		this.selectedSiteADemarc = null;
		this.siteADemarcFormMode = false;
	}

	/* Site Z demarcs */
	loadSiteZDemarcs(siteId: number) {
		if (this.orderService.inventory && this.orderService.inventory.id && siteId) {
			this.demarcService.getDemarcs(siteId, this.orderService.inventory.id).subscribe(
				(results) => {
					this.siteZDemarcs = results.items;

					this.siteZDemarcTmp = this.siteZDemarcs[0] || new Demarc({ unsaved: true });

					this.form.get('inventory').get('site_z_demarc')['controls']['row_id'].patchValue(this.siteZDemarcTmp.row_id);
					this.form.get('inventory').get('site_z_demarc')['controls']['rack_id'].patchValue(this.siteZDemarcTmp.rack_id);
					this.form.get('inventory').get('site_z_demarc')['controls']['shelf_id'].patchValue(this.siteZDemarcTmp.shelf_id);
					this.form.get('inventory').get('site_z_demarc')['controls']['cpe_id'].patchValue(this.siteZDemarcTmp.cpe_id);
					this.form.get('inventory').get('site_z_demarc')['controls']['card_id'].patchValue(this.siteZDemarcTmp.card_id);
					this.form.get('inventory').get('site_z_demarc')['controls']['notes'].patchValue(this.siteZDemarcTmp.notes);
					this.form.get('inventory').get('site_z_demarc')['controls']['extended'].patchValue(this.siteZDemarcTmp.extended);
					this.form.get('inventory').get('site_z_demarc')['controls']['port_id'].patchValue(this.siteZDemarcTmp.port_id);

					/*  this.form.controls['site_z_demarc'].setValue(tempZDemarc); */
				},
				(err) => {
					console.log('Error loading Site Z Demarcs');
				}
			);
		}
	}

	onSiteZDemarcAddRequested(event) {
		this.selectedSiteZDemarc = null;
		this.siteZDemarcFormMode = true;
	}

	onSiteZDemarcEditRequested(demarc: Demarc) {
		this.selectedSiteZDemarc = demarc;
		this.siteZDemarcFormMode = true;
	}

	onSiteZDemarcDeleteRequested(demarc: Demarc) {
		if (demarc.id && !demarc.unsaved) {
			this.demarcService.delete(demarc.id).subscribe(
				(result) => {},
				(err) => {
					console.log('Error delete demarc');
				}
			);
		}
		this.siteZDemarcs = this.siteZDemarcs.filter((item) => {
			return item !== demarc;
		});
	}

	onSiteZDemarcSaveRequested(demarc: Demarc) {
		if (this.selectedSiteZDemarc) {
			Object.assign(this.selectedSiteZDemarc, demarc);
		} else {
			this.siteZDemarcs = this.siteZDemarcs || [];
			this.siteZDemarcs.push(demarc);
		}
		this.selectedSiteZDemarc = null;
		this.siteZDemarcFormMode = false;
	}

	onSiteZDemarcFormCancelled(event) {
		this.selectedSiteZDemarc = null;
		this.siteZDemarcFormMode = false;
	}

	/* insert demarcs */

	// TODO: refactor using bulk create and bulk update
	insertDemarcs(inventoryId) {
		this.siteADemarcs = this.siteADemarcs || [];
		this.siteZDemarcs = this.siteZDemarcs || [];

		let unsavedDemarcs = this.siteADemarcs
			.filter((item) => {
				return item.unsaved;
			})
			.concat(
				this.siteZDemarcs.filter((item) => {
					return item.unsaved;
				})
			);

		let demarcsToUpdate = this.siteADemarcs
			.filter((item) => {
				return item.id;
			})
			.concat(
				this.siteZDemarcs.filter((item) => {
					return item.id;
				})
			);

		unsavedDemarcs.forEach((demarc) => {
			demarc.network_circuit_id = inventoryId;
			this.demarcService.create(demarc).subscribe(
				(result) => {},
				(err) => {
					console.log('Error save demarcs');
				}
			);
		});

		demarcsToUpdate.forEach((demarc) => {
			demarc.network_circuit_id = inventoryId;
			this.demarcService.update(demarc.id, demarc).subscribe(
				(result) => {},
				(err) => {
					console.log('Error update demarcs');
				}
			);
		});
	}

	cancel() {
		this.orderServiceService.cancelEdit();
		this.closeDialog();
	}

	ngOnDestroy() {
		this.destroy$.next(true);
		this.destroy$.complete();
		this.tabGroup.onActivate.unsubscribe();
		this.subclientSubscribe.unsubscribe();
	}

	onContactsChanged(event: any) {
		this.form.controls['contacts'].setValue(event);
	}

	getObjectProperty(obj, prop) {
		return _.get(obj, prop);
	}

	disableDeleteButton() {
		let disableButton: Array<number>;
		let serviceStatus: number;
		if (ORDER_TYPE.DISCONNECT === this.order.type_id) {
			disableButton = [
				ORDER_SERVICE_DISCONNECT_STATUS.VENDOR_ACCEPTED,
				ORDER_SERVICE_DISCONNECT_STATUS.DISCONNECT_DATE,
				ORDER_SERVICE_DISCONNECT_STATUS.STOP_BILLING_DATE,
				ORDER_SERVICE_DISCONNECT_STATUS.DISCONNECT_COMPLETE_DATE,
				ORDER_SERVICE_DISCONNECT_STATUS.COMPLETED
			];
			serviceStatus = this.orderService.disconnect_state;
		}

		if (serviceStatus && disableButton.includes(serviceStatus)) {
			return true;
		}
		return false;
	}

	onAccountChange(event) {
		if (event.selection && event.selection.id) this.selectedBan = event.selection;
		else this.selectedBan = null;
	}
	oderTypeRequiredFieldFunc() {
		if (this.order.type_id === ORDER_TYPE.INSTALL) {
			return (this.orderTypeRequiredField = true);
		} else {
			return (this.orderTypeRequiredField = false);
		}
	}
}
